import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import * as Styled from './styles';
import Layout from 'components/Layout';
import AppStoreImageLinks from 'components/ui/AppStoreImageLinks';

type AppPromotionProps = {
  useTaskMessage: boolean;
};

const AppPromotion: React.FC<AppPromotionProps>= ( { useTaskMessage = false } : AppPromotionProps ) => {
    const { appImage } = useStaticQuery(graphql`
    query {
      appImage: file(relativePath: { eq: "heroImage.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  
  let subTitle;

  if (useTaskMessage) {
    subTitle = 'This page describes one of many steps towards buying a home. Want to keep track of your home buying journey? Download our completely free app! \
    It contains live interest rates and a step by step guide on how to buy a home in Ireland, from applying for mortgage approval to getting your keys.';
  } else {
    subTitle = 'Lively was created as a passion project to help buyers through the complicated and often overwhelming process of buying a home. Download our completely free app containing the best mortgage calculator out there, a step by step guide on how to buy a home in Ireland and live interest rates!';  }

  return (
    <>
        <Styled.Title>Lively is Making it Simpler To Buy A Home in Ireland</Styled.Title>
        <Styled.Testimonial>
                <Styled.ReviewText>{'"Life Saver!"'}</Styled.ReviewText>
                <Styled.Stars>★★★★★</Styled.Stars>
        </Styled.Testimonial>
        <Styled.SubTitle>{subTitle}</Styled.SubTitle>
        <AppStoreImageLinks />
        <Styled.Image><Img fluid={appImage.childImageSharp.fluid} alt="Lively App Screenshots"/></Styled.Image>
  </>
  );
};

export default AppPromotion;