import styled from 'styled-components';
import tw from 'tailwind.macro';

export const Banner = styled.section`
  ${tw`bg-gray-100 border-b border-indigo-100`};
`;

export const Content = styled.p`
  ${tw`mb-8`};
`;

export const Title = styled.h3`
  ${tw`pt-4 w-full md:text-3xl text-2xl font-bold text-left text-blue-800`};
`;

export const SubTitle = styled.p`
  ${tw`mb-4 w-full text-m text-left`};
`;

export const BannerText = styled.div`
  ${tw`sm:w-1/2 w-full mx-auto`};
`;

export const BannerAppStoreImages = styled.div`
  ${tw`mx-auto flex items-center justify-center w-full`};
`;

export const Image = styled.figure`
  ${tw`mx-auto sm:w-1/2 w-full`};
`;

export const AppStoreImage = styled.figure`
  ${tw`mx-auto px-2 pb-1`};
`;

export const Testimonial = styled.div`
  ${tw`flex flex-col items-center text-center w-full my-2`};
`;

export const ReviewText = styled.h3`
  ${tw`italic mb-0 text-xl`};
`;

export const Stars = styled.p`
  ${tw`font-semibold text-yellow-400`};
`;
